<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">时间 : </span>
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">区域 : </span>
              <div class="width250">
                <el-input
                  v-model="searchData.regionName"
                  placeholder="区域编号/区域名称"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">车辆编号 : </span>
              <div class="width250">
                <el-input
                  v-model="searchData.carCode"
                  placeholder="车辆编号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class="marginLeft10"
              >查询</el-button>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class="marginLeft10"
              >重置</el-button>
            </div>

            <div class="marginLeft10 flex align-items marginRight10 marginBottom10">
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-download"
                @click="handleExport"
              >导出</el-button>
            </div>
          </div>
        </div>

        <div class="order-box">
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.orderDuration }}</h3>
            <div class="order-box-info">车辆订单时长(分钟)</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.transferDuration }}</h3>
            <div class="order-box-info">故障换车时长(分钟)</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.totalDuration }}</h3>
            <div class="order-box-info">车辆总时长(分钟)</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.salePrice }}</h3>
            <div class="order-box-info">销售额(元)</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.orderNum }}</h3>
            <div class="order-box-info">订单数量</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.returnNum }}</h3>
            <div class="order-box-info">退货量</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.refundPrice }}</h3>
            <div class="order-box-info">退款金额(元)</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.totalPrice }}</h3>
            <div class="order-box-info">订单金额(元)</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.depositPrice }}</h3>
            <div class="order-box-info">押金金额(元)</div>
          </div>
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.preferentialPrice }}</h3>
            <div class="order-box-info">优惠金额(元)</div>
          </div>
        </div>
      </template>

    </avue-crud>

  </div>
</template>

<script>
import { businessList, businessCount } from "@/api/region/business";
import dayjs from "dayjs";
import qs from "qs";
export default {
  name: "index",
  data() {
    return {
      searchData: {
        createTime: "",
        carCode: "",
        regionName: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "统计报表-运营",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: "区域编号",
            prop: "regionCode",
          },
          {
            label: "区域名称",
            prop: "regionName",
          },
          {
            label: "车辆类型",
            prop: "carTypeName",
          },
          {
            label: "车辆编号",
            prop: "code",
            width:150
          },
          {
            label: "车辆订单时长（分钟）",
            prop: "orderDuration",
            width:150
          },
          {
            label: "故障换车时长（分钟）",
            prop: "transferDuration",
            width:150
          },
          {
            label: "车辆总时长（分钟）",
            prop: "totalDuration",
            width:150
          },
          {
            label: "销售额（元）",
            prop: "salePrice",
          },
          {
            label: "订单数量",
            prop: "orderNum",
          },
          {
            label: "退货量",
            prop: "returnNum",
          },
          {
            label: "退款金额(元)",
            prop: "refundPrice",
          },
          {
            label: "订单金额(元)",
            prop: "totalPrice",
          },
          {
            label: "押金金额(元)",
            prop: "depositPrice",
          },
          {
            label: "优惠金额(元)",
            prop: "preferentialPrice",
          },
        ],
      },
      form: {
        id: "",
        createTime: "",
      },
      orderData: {
        couponPayPrice: 0,
        couponPrice: 0,
        memberPrice: 0,
        payPrice: 0,
        returnPrice: 0,
        totalPrice: 0,
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = {
        carCode: searchForm.searchData.carCode,
        regionName: searchForm.searchData.regionName,
      };
      if (
        searchForm.searchData?.createTimeStart &&
        searchForm.searchData?.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData?.createTimeStart,
          searchForm.searchData?.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
    this.businessCount();
  },
  methods: {
    businessCount() {
      const searchData = {
        carCode: this.searchData.carCode,
        regionName: this.searchData.regionName,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      businessCount({
        ...searchData,
      }).then((res) => {
        this.orderData = res.data;
      });
    },
    onLoad() {
      const searchData = {
        carCode: this.searchData.carCode,
        regionName: this.searchData.regionName,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify({ ...searchForm }));

      this.showLoading = true;

      businessList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
      this.businessCount();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        createTime: "",
        carCode: "",
        regionName: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
      this.businessCount();
    },

    // 区域详情
    handleDetails(row) {
      this.$router.push({
        path: "/order/manage/detail",
        query: {
          id: row.id,
        },
      });
    },

    // 导出
    handleExport() {
      const searchData = {
        carCode: this.searchData.carCode,
        regionName: this.searchData.regionName,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/region/business/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },
  },
};
</script>

<style scoped>
.order-box {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  background: #f6f6f6;
  padding: 20px;
  margin-bottom: 20px;
}
.order-box-list {
  width: 16.5%;
  text-align: center;
}
.order-box-title {
  font-size: 45px;
  font-weight: 400;
  height: 70px;
}
.order-box-info {
  color: #999;
}
</style>